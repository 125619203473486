.graficos-select {
  position: absolute;
  z-index: 999;
  background-color: #ccc;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border-radius: 0.3em;
  overflow-y: scroll;
  max-height: 20em;
}
.select-chart {
  cursor: pointer;
}
body {
  overflow-x: hidden !important;
}
